<template>
  <v-stepper class="elevation-0">
    <v-stepper-header>
      <v-stepper-step :color="getColor(checkStatus('agreed_to_terms'))" :complete="checkStatus('agreed_to_terms')" step="1">
        {{ $store.getters.translate("agreed_to_terms") }}
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step :complete="checkStatus('passed_all_sessions')" step="2">
        {{ $store.getters.translate("passed_all_sessions") }}
      </v-stepper-step>
      <template v-if="dossier.scheme && dossier.scheme.end_result !== 'stipel'">
        <v-divider/>
        <v-stepper-step
          :editable="!checkStatus('is_certified') && checkStatus('certifiable')"
          :complete="checkStatus('certifiable')"
          step="3"
          @click="createCertificate">
          {{ $store.getters.translate("certifiable") }}
          <small v-if="!checkStatus('is_certified') && checkStatus('certifiable') && !checkStatus('pending')">
            {{ $store.getters.translate("certify_this_candidate") }}
          </small>
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete="checkStatus('is_certified')" step="4">
          {{ $store.getters.translate("is_certified") }}
        </v-stepper-step>
      </template>
      <template v-else>
        <v-divider/>
        <v-stepper-step :complete="checkStatus('is_certified')" step="3">
          {{ $store.getters.translate("is_certified") }}
        </v-stepper-step>
<!--    <v-divider/>
        <v-stepper-step
          editable
          :complete="checkStatus('certifiable')"
          step="3"
          @click="createDnvRequest">
          {{ $store.getters.translate("certifiable") }}
          <small v-if="!checkStatus('is_certified') && checkStatus('certifiable') && !checkStatus('certificate_requested')">
            {{ $store.getters.translate("send_to_dnv") }}
          </small>
          <small v-if="!checkStatus('is_certified') && checkStatus('certificate_requested')">
            {{ $store.getters.translate("certificate_requested") }}
          </small>
        </v-stepper-step>-->
      </template>
    </v-stepper-header>
    <create-certificate
      v-if="dossier.scheme && dossier.scheme.end_result !== 'stipel'"
      modal_name="create_certificate"
      ref="create_certificate"
      :dossier="dossier"
      @change="editCertificate"/>
<!--<create-dnv-request
      v-if="dossier.scheme && dossier.scheme.end_result === 'stipel'"
      ref="create_dnv_request"
      modal_name="create_dnv_request"
      :dossier="dossier"
      :re_examination="re_examination"
      @document_preview="preview"
      @refresh="$emit('refresh')"/>-->
  </v-stepper>
</template>

<script>
import CreateCertificate from "../../certificates/Create";
//import CreateDnvRequest from "./CreateDnvRequest";

export default {
  props: ["dossier", "re_examination", "exam_id"],
  components: {
    CreateCertificate,
    //CreateDnvRequest,
  },
  data() {
    return {
      key: 0,
    };
  },
  methods: {
    checkStatus(status) {
      if (!this.dossier.statuses) {
        return false;
      }
      let exists = this.dossier.statuses.find((status_node) => status_node.name === status);
      if (exists) {
        return true;
      }
      return false;
    },
    createCertificate() {
      if (!this.checkStatus("is_certified") && this.checkStatus("certifiable")) {
        this.$refs.create_certificate.exam_id = this.exam_id;
        this.$refs.create_certificate.openModal('create_certificate');
      }
    },
    /*createDnvRequest() {
      this.$refs.create_dnv_request.openModal('create_dnv_request');
      this.$refs.create_dnv_request.load();
    },*/
    editCertificate(data) {
      this.$emit("change", data);
    },
    preview(file) {
      this.$emit("document_preview", file);
    },
    getColor(status) {
      return status ? "primary" : "#9E9E9E";
    },
  },
};
</script>