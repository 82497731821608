<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" dense flat slot="header">
          <v-btn icon @click.stop="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ page.title }}</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
        <div slot="content">
      <v-divider/>
      <v-card-text>
        <div class="ma-5">
          <v-checkbox
            hide-details
            :label="$store.getters.translate('add_existing_certificate')"
            v-model="add_existing_certificate"/>
          <v-file-input
            hide-details
            v-if="add_existing_certificate"
            :label="$store.getters.translate('certificate')"
            show-size
            v-model="file"
            :hint="$store.getters.translate('optional_field')"/>
          <v-checkbox
            hide-details
            :label="$store.getters.translate('manually_set_issued_on')"
            v-model="manually_set_issued_on"/>
          <v-text-field
            hide-details
            v-if="manually_set_issued_on"
            :label="$store.getters.translate('issued_on')"
            v-model="issued_on"
            v-mask="'##-##-####'"
            placeholder="dd-mm-yyyy"
            :hint="$store.getters.translate('optional_field')"/>
          <v-checkbox
            hide-details
            :label="$store.getters.translate('manually_set_valid_until')"
            v-model="manually_set_valid_until"/>
          <v-text-field
            hide-details
            v-if="manually_set_valid_until"
            :label="$store.getters.translate('valid_until')"
            v-model="valid_until"
            v-mask="'##-##-####'"
            placeholder="DD-MM-JJJJ"
            :hint="$store.getters.translate('optional_field')"/>
          <v-checkbox
            hide-details
            :label="$store.getters.translate('manually_set_certificate_number')"
            v-model="manually_set_certificate_number"/>
          <v-text-field
            hide-details
            v-if="manually_set_certificate_number"
            :label="$store.getters.translate('certificate_number')"
            v-model="record.certificate_number"
            :hint="$store.getters.translate('optional_field')"/>
          <v-text-field
              aria-required="true"
              v-if="manually_set_place_of_birth"
              :label="$store.getters.translate('place_of_birth') + ' (' + $store.getters.translate('required_for_certificate') + ')'"
              v-model="record.place_of_birth"
              :hint="$store.getters.translate('required')"/>
        </div>
      </v-card-text>
        </div>
        <v-footer slot="footer">
        <v-btn plain color="primary" block @click="save">
          <v-icon left>mdi-content-plus</v-icon>
          {{ $store.getters.translate(add_existing_certificate ? "attach_certificate" : "generate_certificate") }}
        </v-btn>
      </v-footer>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
import moment from "moment";

export default {
  props: ["dossier", "modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("certificate"),
        name: "certificates",
        model: "certificate",
      },
      record: {
        name: "",
        files: [],
        attach_certificate: false,
        place_of_birth: null
      },
      exam_id: null,
      issued_on: null,
      valid_until: null,
      manually_set_issued_on: false,
      manually_set_valid_until: false,
      manually_set_certificate_number: false,
      manually_set_place_of_birth: false,
      loading: false,
      file: null,
      add_existing_certificate: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      if(!this.loading) {
        this.loading = true;
        const formData = new FormData();
        formData.append("certificate", this.file);
        formData.append("candidate_id", this.dossier.candidate.id);
        formData.append("id", this.dossier.id);
        if (this.file) {
          formData.append("attach_certificate", true);
        }
        formData.append("certificate_number", this.record.certificate_number);
        if (this.manually_set_issued_on && this.issued_on) {
          formData.append("issueing_date", this.issued_on);
        }
        if (this.manually_set_valid_until && this.valid_until != undefined) {
          formData.append("valid_until", this.valid_until);
        }
        formData.append("exam_id", this.exam_id);
        if (this.record.tags != undefined) {
          formData.append("tags", this.record.tags);
        }
        if (this.record.place_of_birth != undefined) {
          formData.append("place_of_birth", this.record.place_of_birth);
        }
        this.$http
            .post(this.$store.getters.appUrl + "v2/" + this.page.name, formData, {
              headers: {"Content-Type": "multipart/form-data"},
            })
            .then((response) => {
              this.loading = false;
              if (response.headers['content-type'] == "application/pdf") {
                this.$toasted.error(this.$store.getters.translate("has_certificate_in_pending_approval"));
              } else {
                this.$toasted.success(this.$store.getters.translate("the_certificate_has_been_created"));
                setTimeout(() => this.$emit("change", response.data), 3000);
              }
              this.reset();
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                if (error.response.status === 422) {
                  if (error.response.data.error === 'no_place_of_birth_present') {
                    this.manually_set_place_of_birth = true;
                    this.$toasted.info(this.$store.getters.translate(error.response.data.error));
                  } else {
                    this.$toasted.error(this.$store.getters.translate(error.response.data.error));
                  }
                }
              }
              this.loading = false;
            });
      }
    },
    reset() {
      this.issued_on = null;
      this.valid_until = null;
      this.record = {
        name: "",
        files: [],
        attach_certificate: false,
        place_of_birth: null
      };
      this.closeModal(this.modal_name);
    },
  },
  watch: {
    issued_on: {
      handler() {
        if (this.issued_on && moment(this.issued_on, "DD-MM-YYYY", true).isValid()) {
          this.valid_until = moment(this.issued_on, "DD-MM-YYYY").add(3, 'years').format("DD-MM-YYYY");
        }
        else {
          this.valid_until = null;
        }
      },
    },
  },
};
</script>
